import React from "react";
import { graphql } from "gatsby";

// Sections
import Hero from "../components/sections/Hero";
import HeroSeparator from "../components/sections/HeroSeparator";
import Intro from "../components/sections/Intro";
import Process from "../components/sections/Process";
import Clients from "../components/sections/Clients";
import Services from "../components/sections/Services";
import Cta from "../components/sections/Cta";
import Layout from "../layouts";

// Images
import bgHero from "../assets/images/bg-hero.jpg";

const Home = ({ data }) => {
	return (
		<Layout>
			<Hero hero={bgHero} video className="video">
				<div className="container h-100 align-center-center px-0">
					<h1 className="h1">
						<span>A Serverless</span> <span>approach to</span> <span>complex digital</span> <span>challenges.</span>
					</h1>
				</div>
			</Hero>
			<HeroSeparator className="home" />
			<Intro />
			<Process />
			<Clients />
			<Services />
			<Cta />
		</Layout>
	);
};

export default Home;

export const query = graphql`
  query HomePageQuery {
    latestPost: allMarkdownRemark(
      limit: 1,
      filter: {
        fileAbsolutePath: {
         regex: "/\\/content\\/blog-posts\\//"
        }
      }
      sort: {
        fields: [frontmatter___date],
        order: DESC
      }
    ) {
      edges {
        ...BlogPostExcerpt
      }
    }
  }
`;
