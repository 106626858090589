import React from "react";
import Image from "../elements/Image";

// Images
import logoOJF from "../../assets/images/logo-orangejellyfish-text.svg";
import logoOJFMobile from "../../assets/images/logo-orangejellyfish-text-mobile.svg";

const Intro = () => {
	return (
		<section className="section-intro">
			<div className="container">
				<p>
					We are experts in software <br />
					development and delivery.
				</p>
				<p>
					We navigate digital projects with <br />
					passion, expertise and ingenuity.
				</p>
				<p className="m-0">We are</p>
				<div className="logo-text">
					<Image src={logoOJF} alt="orangejellyfish" className="mobile-hidden" />
					<Image src={logoOJFMobile} alt="orangejellyfish" className="desktop-hidden" />
				</div>
			</div>
		</section>
	);
};

export default Intro;
