import React from "react";
import ProcessStep from "./partials/ProcessStep";

const Process = () => {
	const stepsDataStatic = [
		{
			number: "01.",
			title: "BREADTH AND DEPTH",
			content:
				"We’ll provide you with the best engineers in the industry. They’re part of our well-rounded team of experts with deep experience across UI/UX, product management and project delivery. Together, we’ll make your software opportunity a reality.",
			offset: "0",
		},
		{
			number: "02.",
			title: "Fluid and adept",
			content:
				"Years of enterprise-level experience have honed our ability to get under the skin of new challenges and find the right solutions. Working in partnership with your project leads, we’ll quickly assess the situation and together define or refine your goals. And if the goalposts shift, we’ll be ready.",
			offset: "0",
		},
		{
			number: "03.",
			title: "Craft with heart",
			content:
				"We do what we do because we love it. We’re passionate about the craft of software development, and take great pride in the quality of what we deliver. No matter the size of your project, we’ll approach it with the highest levels of care and dedication.",
			offset: "1",
		},
		{
			number: "04.",
			title: "Future focused",
			content:
				"Caring about a product after we leave – about the entire software lifecycle – is an essential part of the craft. Whatever our future involvement, we’re committed to your project’s long-term success. We nurture, we grow and then we hand over your product, ready to be cherished and evolved by you.",
			offset: "2",
		},
		{
			number: "05.",
			title: "Always learning",
			content:
				"Whatever their role, everyone at orange jellyfish is united by a sense of continually wanting to be even better. We’re driven by our work with ambitious startups and we’re opinion leaders at the cutting edge of software craftsmanship and innovation.",
			offset: "2",
		},
	];

	return (
		<section className="section-process">
			<div className="container">
				<h2>This is how we work</h2>
				<div className="section-process__steps">
					{stepsDataStatic.map((step, key) => {
						return <ProcessStep key={key} number={step.number} title={step.title} content={step.content} offset={step.offset} />;
					})}
				</div>
			</div>
		</section>
	);
};

export default Process;
