import React from "react";
import Image from "../elements/Image";

// Images
import imgServices from "../../assets/images/img-services.jpg";

const Services = () => {
	// Dummy data
	const servicesDataStatic = [
		{
			title: "JavaScript",
		},
		{
			title: "Node.js",
		},
		{
			title: "Serverless",
		},
		{
			title: "AWS",
		},
		{
			title: "React",
		},
	];

	return (
		<section className="section-services">
			<div className="container">
				<div className="section-services__inner">
					<div className="row">
						<div className="col-lg-7">
							<div className="section-services__inner__img">
								<h4>We're experts in...</h4>
								<Image src={imgServices} className="img-fluid" alt="Services" />
							</div>
						</div>
						<div className="col-lg-5">
							<div className="section-services__inner__content">
								<h2>
									{servicesDataStatic.map((service, key) => {
										return <span key={key}>{service.title}</span>;
									})}
								</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Services;
