import React from "react";
import PropTypes from "prop-types";
import ScrollAnimation from "react-animate-on-scroll";

// Animate.css
import "animate.css/animate.min.css";

const propTypes = {
	number: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
	offset: PropTypes.string.isRequired,
};

const defaultProps = {
	number: undefined,
	title: undefined,
	content: undefined,
	offset: "0",
};

const ProcessStep = ({ ...props }) => {
	return (
		<ScrollAnimation animateIn="bounceInUp" duration={2} animateOnce={true}>
			<div className={`section-process__steps--step offset-${props.offset}`}>
				<div className="section-process__steps--step__inner">
					<h2>{props.number}</h2>
					<h4>{props.title}</h4>
					<p>{props.content}</p>
				</div>
			</div>
		</ScrollAnimation>
	);
};

ProcessStep.propTypes = propTypes;
ProcessStep.defaultProps = defaultProps;

export default ProcessStep;
