import React from "react";
import Slider from "react-slick";

// Images
import logoEE from "../../assets/images/logos/equal-experts.svg";
import logoTesco from "../../assets/images/logos/tesco.svg";
import logoLS from "../../assets/images/logos/la-salle.svg";
import logoUBS from "../../assets/images/logos/ubs.svg";
import logoMindset from "../../assets/images/logos/mindset.png";
import logoTendo from "../../assets/images/logos/tendo.svg";
import logoPQCode from "../../assets/images/logos/pqcode.png";
import logoEconomist from "../../assets/images/logos/economist.svg";
import Image from "../elements/Image";

const Clients = () => {
	const clientsDataStatic = [
		{
			title: "La Salle Education",
			logo: logoLS,
		},
		{
			title: "The Economist",
			logo: logoEconomist,
		},
		{
			title: "Tesco",
			logo: logoTesco,
		},
		{
			title: "UBS",
			logo: logoUBS,
		},
		{
			title: "Equal Experts",
			logo: logoEE,
		},
		{
			title: "Mindset",
			logo: logoMindset,
		},
		{
			title: "Tendo",
			logo: logoTendo,
		},
		{
			title: "pqCode",
			logo: logoPQCode,
		},
	];

	const sliderSettings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 2,
		responsive: [
			{
				breakpoint: 575,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<section className="section-clients">
			<div className="container">
				<h2>These are our clients</h2>
				<div className="section-clients__logos">
					<Slider {...sliderSettings}>
						{clientsDataStatic.map((client, key) => {
							return (
								<div className="section-clients__logos--logo" key={key}>
									<Image src={client.logo} className="img-fluid" alt={client.title} />
								</div>
							);
						})}
					</Slider>
				</div>
			</div>
		</section>
	);
};

export default Clients;
